import React from "react";

import Page from "lib/@cms/components/layout/Page";
import PageHeader from "lib/@cms/components/cms/PageHeader";
import ContentBox from "lib/@cms/components/shared/ContentBox";
import Button from "lib/@cms/components/primitive/Button";
import Divider from "lib/@cms/components/primitive/Divider";

function CalculatorsPage() {
  return (
    <Page pathname="calculators">
      {data => {
        return (
          <React.Fragment>
            <PageHeader
              variant={PageHeader.variant.GRADIENTS}
              color={PageHeader.color.PRIMARY}
              data={data.PageHeaderGradients}
            />

            <div className="tw-container">
              {data &&
                data.CalculatorsList?.map((element, index) => (
                  <div key={`BodyList-${index}`}>
                    <ContentBox title={element.title}>
                      <ul className="tw-list-disc tw-ml-6">
                        {element.list?.map((item, index1) => {
                          return (
                            <div key={`BodyList-${index1}`}>
                              <li className="tw-mb-4 tw-mt-6">{item.description}</li>
                              <Button is="a" href={item.buttonUrl} target="_blank" rel="noreferrer">
                                {item.buttonText}
                              </Button>
                            </div>
                          );
                        })}
                      </ul>
                    </ContentBox>
                    <Divider className="tw-my-6" />
                  </div>
                ))}
              <Divider className="tw-my-16 sm:tw-my-24" />
            </div>
          </React.Fragment>
        );
      }}
    </Page>
  );
}

export default CalculatorsPage;
